import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import Layout from "../Main Component/Layout";

//
import BgImg from "../Assets/bread-crumb-img-2.png";

import axios from "axios";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

function Products() {
  const [productData, setProductData] = useState([]);
  const location = useLocation();
  const { state } = location;

  const { id } = useParams();

  useEffect(() => {
    let s_id = "catId=" + id;

    axios
      .post(
        "https://nutrihubipl.com/admin/web-app/categoryWiseProductList.php",
        s_id
      )
      .then((res) => {
        // console.log(res.data.result);
        setProductData(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <>
      <Layout>
        <BreadCrumb BgImg={state.data.slider_img} name={state.data.value} />
        <div className="product-content">
          {/* <h1>{state.data.value}</h1> */}

          <div className="product-container">
            {productData.map((val, i) => {
              return (
                <div className="single-product">
                  <div className="img">
                    <img src={val.product_img} alt="" />
                  </div>
                  <h3>{val.product_name}</h3>
                  <Link to={"/product-info"} state={{ product_details: val }}>
                    View
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Products;
