import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

function Sec_1() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    axios
      .get("https://nutrihubipl.com/admin/web-app/getSliderVdo.php", {
        headers: {
          "Content-Type": "application/json",
          // Add other headers here if necessary
        },
      })
      .then((res) => {
        // console.log("Slider video", res);
        setSliderData(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="sec-1">
        <div className="video-slider-container">
          <Slider {...settings}>
            {sliderData.map((video) => (
              <div key={video.slide_id} className="video-slide">
                {/* <iframe
                  src={`${video.slide_vdo}`}
                  frameBorder="0"
                  // allow="autoplay; fullscreen"
                  allow="autoplay"
                  allowFullScreen
                  className="slider-video"></iframe> */}

                <video
                  className="slider-video"
                  autoPlay
                  muted
                  controls={false}
                  style={{ maxWidth: "100%", height: "auto" }}>
                  <source src={video.slide_vdo} type="video/mp4" />
                </video>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Sec_1;
